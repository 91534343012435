import { defineStore } from 'pinia'
import { pointerType } from '@/utils/device'
import type {
  UserAuthState,
  DeviceObj,
  User,
  UserSettings,
  UserConsents,
} from '@/types/user-auth-types'

function getDefaultUser(): User {
  return {
    anonymizedIp: '',
    isLoggedIn: false,
    displayName: 'Guest',
    firstName: 'guest',
    username: 'guest',
    notificationUnreadCount: 0,
    startMonday: 0,
    showBurnEstimates: 0,
    showBrowseView: 0,
    measurementSystem: 'imperial',
    filterBeta: 0,
    hasPurchased: false,
  }
}

export const useUserAuthStore = defineStore('userAuth', {
  state: (): UserAuthState => ({
    // user data
    user: window.Laravel.user || getDefaultUser(),

    settings: window.Laravel.settings || {},
    consents: window.Laravel.consents || {},
    planPurchases: window.Laravel.planPurchases || {},
    csrfToken: window.Laravel.csrfToken || '',

    // ads free video player IDs, set from ssr
    videoPlayer: window.Laravel.videoPlayer || {},

    // feature flags
    features: window.Laravel.features || {},
    plusFreeAccess: window.Laravel.plusFreeAccess || {
      enabled: false,
      expires: false,
      remainingDays: 0,
      remainingHours: 0,
      availableEndDate: '',
    },

    // user's device settings
    device: {
      pointerType: pointerType(),
    },

    // hacky way to refresh tag data on the following tag components
    // - user-tag-search
    // - mod-menu-tags
    // - manage-tags
    //
    // each component has a slightly different tag query so not caching
    // tag data in store, but do have a need to reload the tags
    // across components
    refreshTagsInstances: false,

    // cdn prefixes, set from ssr _head.blade.php
    // doesn't really belong to userAuth at all, but didn't want to spin
    // up a separate store just for these read-only values
    // cdn prefixes from window.Laravel
    cdn: window.Laravel.cdn || {},
  }),

  getters: {
    completeEffectsEnabled: (state: UserAuthState): boolean => {
      return state.user.completeEffects !== 0
    },

    confettiEnabled: (state: UserAuthState): boolean => {
      return state.user.completeEffects === 1
    },

    isPlanPurchased:
      (state: UserAuthState) =>
      (planId: number): boolean => {
        return !!state.planPurchases[planId]
      },

    displayNameFirstLetter: (state: UserAuthState): string => {
      if (!state.user.isLoggedIn) {
        return ''
      }

      // default to B for blender
      return state.user.displayName ? state.user.displayName.charAt(0) : 'B'
    },

    // TODO: filters - beta end - audit and delete
    showBetaFilters: (state: UserAuthState): boolean => {
      // show to anon
      if (!state.user.isLoggedIn) {
        return true
      }

      // for users, base on pref
      return !!state.user.filterBeta
    },

    isFbAdminUser: (state: UserAuthState): boolean => {
      return !!(state.user?.role && [2, 3, 7].includes(state.user.role))
    },

    isAutoRenewSubscriber: (state: UserAuthState): boolean => {
      if (!state.user.subscriptionPlanId) {
        return false
      }

      return [1, 2].includes(state.user.subscriptionPlanId)
    },

    isPlusPassSubscriber: (state: UserAuthState): boolean => {
      if (!state.user.subscriptionPlanId) {
        return false
      }

      return state.user.subscriptionPlanId === 6
    },

    isFreeUser: (state: UserAuthState): boolean => {
      return !state.features.ADS_FREE
    },
  },

  actions: {
    updateCsrfToken(newToken: string) {
      this.csrfToken = newToken
    },

    updateDevice(device: DeviceObj) {
      this.device = {
        ...this.device,
        ...device,
      }
    },

    updateUser(newUser: Partial<User>) {
      this.user = {
        ...this.user,
        ...newUser,
      }
    },

    // previously called setUserLoggedOut
    logoutUser() {
      this.user = getDefaultUser()
    },

    updateSettings(newSettings: UserSettings) {
      this.settings = {
        ...this.settings,
        ...newSettings,
      }
    },

    updateConsents(newConsents: UserConsents) {
      this.consents = {
        ...this.consents,
        ...newConsents,
      }
    },

    setPlanPurchases(planPurchases: Record<number, boolean>) {
      this.planPurchases = planPurchases
    },

    setRefreshTagsInstances(refresh: boolean) {
      this.refreshTagsInstances = refresh
    },

    // helper method, does not mutate store
    getFirstLetter(displayName: string): string {
      // default to B for blender
      return displayName ? displayName.charAt(0) : 'B'
    },
  },
})
